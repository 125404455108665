import React from 'react';

export default function Flag(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 4.00195H10V44.002H6V4.00195Z" fill="currentColor" />
      <path
        d="M14 28.002C13.4701 28.0004 12.9623 27.7891 12.5875 27.4144C12.2128 27.0397 12.0016 26.5319 12 26.002V6.00195C12.0005 5.67734 12.0798 5.35771 12.2313 5.07061C12.3828 4.78351 12.6018 4.53754 12.8695 4.35391C13.1371 4.17027 13.4454 4.05448 13.7678 4.01651C14.0902 3.97854 14.417 4.01953 14.72 4.13595L40.72 14.136C41.0974 14.2812 41.422 14.5374 41.6508 14.8708C41.8797 15.2043 42.0021 15.5992 42.0018 16.0036C42.0016 16.4081 41.8788 16.8029 41.6496 17.1361C41.4204 17.4693 41.0956 17.7251 40.718 17.87L14.718 27.87C14.4886 27.9569 14.2453 28.0016 14 28.002ZM16 8.91595V23.092L34.43 16.004L16 8.91595Z"
        fill="currentColor"
      />
    </svg>
  );
}

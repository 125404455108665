import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

import Theme, { theme } from '@pagerland/themes/src/RealEstate';
import {
  Navbar,
  Welcome,
  About,
  Location,
  Surroundings,
  Gallery,
  Contact,
  SimilarProperties,
  Footer,
} from '@pagerland/themes/src/RealEstate/containers';

import preview from '@pagerland/themes/src/RealEstate/assets/preview.jpg';

import SEO from '../components/SEO';
import { useStaticQuery, graphql } from "gatsby";
const RealEstate = ({ url }) => {
  let gdata = useStaticQuery(graphql`
  query {
  allContentfulGallery {
    nodes {
      title
      source {
        fixed (width: 340, height: 250) {
          ...GatsbyContentfulFixed
        }
        file{
          url
        }
      }
    }
  }
  allContentfulOurservices {
  
      nodes {
        title
        message {
          message
        }
        photo{
          fixed(width: 350, height: 250){
          ...GatsbyContentfulFixed
        }

        } 
      }
    
  }
}
`)
const data = gdata.allContentfulGallery.nodes; 
const services = gdata.allContentfulOurservices.nodes;
  return (
  <Theme>
    <Helmet>
      <link href={theme.typography.googleFont} rel="stylesheet" />
      <meta name="theme-color" content={theme.colors.primary} />
      <meta property="og:image" content={`${url}${preview}`} />
    </Helmet>
    <SEO title="Sparklean" />

    <Navbar />
    <Welcome name="" />
    <About name="about" />  
    <SimilarProperties name="similar-properties" services={services}/>
    <Surroundings name="surroundings" />
    <Gallery name="gallery" imgs={data} />
    <Location name="location" />
    <Contact name="contact" />
    <Footer name="about-us" />
  </Theme>
)};

RealEstate.propTypes = {
  url: PropTypes.string,
};

RealEstate.defaultProps = {
  url: 'https://www.sparkleanmaster.com/',
};

export default RealEstate;

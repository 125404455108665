import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from '@pagerland/common/src/components/Box';
import Container from '@pagerland/common/src/components/Container';
import Typography from '@pagerland/common/src/components/Typography';
import ArrowRight from '@pagerland/icons/src/line/ArrowRight';
import Button from '@pagerland/common/src/components/Button';
import data from '../../data';
import Input from '@pagerland/common/src/components/Formik/Input';
import { Wrapper } from './styled.components';
import { Form, Formik } from 'formik';
// const soptions = [
//   {value: 'Pet & Stain', label:'Pet & Stain'},
//   {value:'Carpet Clean',label:'Carpet Clean'},
//   {value:'Mattress Clean',label:'Mattress Clean'},
//   {value:'Water & Fire Damage',label:'Water & Fire Damage'},
//   {value:'Tile & Grout Cleaning',label:'Tile & Grout Cleaning'},
//   {value:'Air Dust Cleaning',label:'Air Dust Cleaning'}
// ]

const Interior = ({
  name,
  sections,
  WrapperProps,
  ContainerProps,
  SectionProps,
  CaptionProps,
  TitleProps,
  TextProps,
  form,
  FormProps,
  FormTitleProps,
  FormButtonProps,
}) =>

  // const [selected, setSelected] = useState([]);
  // const _select = (e) => {
  //   setSelected(e);
  //   console.log(selected)
  // }
   (
    <Box name={name} {...WrapperProps}>
      <Container {...ContainerProps}>
        {sections.map((section, key) => (
          <Wrapper {...SectionProps} key={key}>
            <Box {...CaptionProps}>
              <Fade bottom cascade duration={600}>
                <Typography {...TitleProps}>{section.title}</Typography>
                <Typography {...TextProps}>{section.text}</Typography>
              </Fade>
            </Box>
            <Box {...FormProps}>
            <Formik
              validationSchema={form.validationSchema}
              onSubmit={form.onSubmit}
              initialValues={form.fields.reduce(
                (acc, field) => ({
                  ...acc,
                  [field.name]: field.initialValue,
                }),
                {},
              )}
            >
              <Form>
                <Fade cascade bottom duration={600}>
                  <Typography {...FormTitleProps}>{form.title}</Typography>
                  <div>
                    {/* <Select options={soptions} isMulti name="serviceType" onChange={_select} /> */}
                    {form.fields.map(field => (
                      <Input key={field.name} {...field} />
                    ))}
                    <Button type="submit" {...FormButtonProps}>
                      {form.sendButtonText}
                    </Button>
                  </div>
                </Fade>
              </Form>
            </Formik>
          </Box>
            
          </Wrapper>
        ))}
      </Container>
    </Box>
  );


Interior.propTypes = {
  name: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      slides: PropTypes.arrayOf(PropTypes.object),
      title: PropTypes.node,
      text: PropTypes.node,
    }),
  ),
  WrapperProps: PropTypes.object,
  ContainerProps: PropTypes.object,
  SectionProps: PropTypes.object,
  CaptionProps: PropTypes.object,
  TitleProps: PropTypes.object,
  TextProps: PropTypes.object,
  ImgProps: PropTypes.object,
};

Interior.defaultProps = {
  WrapperProps: {
    pt: {
      _: 32,
      md: 64,
      lg: 160,
    },
    pb: {
      _: 64,
      lg: 160,
    },
  },
  SectionProps: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: {
      _: 'column',
      lg: 'row',
    },
  },
  CaptionProps: {
    flexGrow: 0,
    flexShrink: 0,
    maxWidth: {
      _: 'none',
      lg: 448,
    },
    mb: {
      _: 5,
      lg: 0,
    },
  },
  TitleProps: {
    as: 'h3',
    variant: 'h3',
    color: 'gray.0',
    mb: 3,
  },
  TextProps: {
    color: 'gray.1',
  },

  ImgProps: {
    width: '100%',
  },
  FormProps: {
    gridColumn: {
      md: '1 / 3',
      lg: 'auto',
    },
  },
  FormTitleProps: {
    as: 'h3',
    variant: 'h3',
    mb: {
      _: 3,
      md: 4,
      lg: 48,
    },
  },
  FormButtonProps: {
    variant: 'brand',
    mt: 24,
    icon: ArrowRight,
  },
  ...data.interior,
};

export default Interior;

import React from 'react';

import WebGrid from '@pagerland/icons/src/line/WebGrid';
import BedDouble from '@pagerland/icons/src/line/BedDouble';
import Bath from '@pagerland/icons/src/line/Bath';
import CarSideview from '@pagerland/icons/src/line/CarSideview';
import Home from '@pagerland/icons/src/line/Home';
import Bug from '@pagerland/icons/src/line/Bug';
import ShoppingBag from '@pagerland/icons/src/line/ShoppingBag';
import Bus from '@pagerland/icons/src/line/Bus';
import Metro from '@pagerland/icons/src/line/Metro';
import Beach from '@pagerland/icons/src/line/Beach';
import Trees from '@pagerland/icons/src/line/Trees';
import Flag from '@pagerland/icons/src/line/Flag';
import User from '@pagerland/icons/src/line/User';
import Building from '@pagerland/icons/src/line/Building';
import MapMarker from '@pagerland/icons/src/line/MapMarker';
import Phone from '@pagerland/icons/src/line/Phone';
import Envelope from '@pagerland/icons/src/line/Envelope';

import TagAlt from '@pagerland/icons/src/line/TagAlt';
import ThumbsUp from '@pagerland/icons/src/line/ThumbsUp';
import ShieldCheck from '@pagerland/icons/src/line/ShieldCheck';
import Award from '@pagerland/icons/src/line/Award';

import Icon from '@pagerland/common/src/components/Icon';

import textToMultiline from '@pagerland/common/src/utils/textToMultiline';

import * as Yup from 'yup';
import background from './assets/header-photo.jpg';
import background2x from './assets/header-photo@2x.jpg';

import about1 from './assets/about/about-1.jpg';
import aboutLarge1 from './assets/about/about-huge-1.jpg';
import about12x from './assets/about/about-1@2x.jpg';
import about2 from './assets/about/about-2.jpg';
import aboutLarge2 from './assets/about/about-huge-2.jpg';
import about22x from './assets/about/about-2@2x.jpg';
import about3 from './assets/about/about-3.jpg';
import aboutLarge3 from './assets/about/about-huge-3.jpg';
import about32x from './assets/about/about-3@2x.jpg';

// import exterior1 from './assets/exteriors/exterior-1.jpg';
// import exterior12x from './assets/exteriors/exterior-1@2x.jpg';
// import exterior2 from './assets/exteriors/exterior-2.jpg';
// import exterior22x from './assets/exteriors/exterior-2@2x.jpg';

import interior2 from './assets/interiors/interior-2.jpg';
import interior22x from './assets/interiors/interior-2@2x.jpg';

// import surrounding1 from './assets/surroundings/surroundings-1.jpg';
// import surrounding12x from './assets/surroundings/surroundings-1@2x.jpg';
// import surrounding2 from './assets/surroundings/surroundings-2.jpg';
// import surrounding22x from './assets/surroundings/surroundings-2@2x.jpg';
import surrounding3 from './assets/surroundings/surroundings-3.jpg';
import surrounding32x from './assets/surroundings/surroundings-3@2x.jpg';

import gallery1 from './assets/gallery/gallery-1.jpg';
import gallery2 from './assets/gallery/gallery-2.jpg';
import gallery3 from './assets/gallery/gallery-3.jpg';
import gallery4 from './assets/gallery/gallery-4.jpg';
import gallery5 from './assets/gallery/gallery-5.jpg';
import gallery6 from './assets/gallery/gallery-6.jpg';
import gallery7 from './assets/gallery/gallery-7.jpg';
import gallery8 from './assets/gallery/gallery-8.jpg';
import gallery9 from './assets/gallery/gallery-9.jpg';
import gallery10 from './assets/gallery/gallery-10.jpg';
import gallery11 from './assets/gallery/gallery-11.jpg';
import gallery12 from './assets/gallery/gallery-12.jpg';
import gallery13 from './assets/gallery/gallery-13.jpg';
import gallery14 from './assets/gallery/gallery-14.jpg';
import gallery15 from './assets/gallery/gallery-15.jpg';
import gallery1Thumbnail from './assets/gallery/thumbnails/gallery-1.jpg';
import gallery12xThumbnail from './assets/gallery/thumbnails/gallery-1@2x.jpg';
import gallery2Thumbnail from './assets/gallery/thumbnails/gallery-2.jpg';
import gallery22xThumbnail from './assets/gallery/thumbnails/gallery-2@2x.jpg';
import gallery3Thumbnail from './assets/gallery/thumbnails/gallery-3.jpg';
import gallery32xThumbnail from './assets/gallery/thumbnails/gallery-3@2x.jpg';
import gallery4Thumbnail from './assets/gallery/thumbnails/gallery-4.jpg';
import gallery42xThumbnail from './assets/gallery/thumbnails/gallery-4@2x.jpg';
import gallery5Thumbnail from './assets/gallery/thumbnails/gallery-5.jpg';
import gallery52xThumbnail from './assets/gallery/thumbnails/gallery-5@2x.jpg';
import gallery6Thumbnail from './assets/gallery/thumbnails/gallery-6.jpg';
import gallery62xThumbnail from './assets/gallery/thumbnails/gallery-6@2x.jpg';
import gallery7Thumbnail from './assets/gallery/thumbnails/gallery-7.jpg';
import gallery72xThumbnail from './assets/gallery/thumbnails/gallery-7@2x.jpg';
import gallery8Thumbnail from './assets/gallery/thumbnails/gallery-8.jpg';
import gallery82xThumbnail from './assets/gallery/thumbnails/gallery-8@2x.jpg';
import gallery9Thumbnail from './assets/gallery/thumbnails/gallery-9.jpg';
import gallery92xThumbnail from './assets/gallery/thumbnails/gallery-9@2x.jpg';
import gallery10Thumbnail from './assets/gallery/thumbnails/gallery-10.jpg';
import gallery102xThumbnail from './assets/gallery/thumbnails/gallery-10@2x.jpg';
import gallery11Thumbnail from './assets/gallery/thumbnails/gallery-11.jpg';
import gallery112xThumbnail from './assets/gallery/thumbnails/gallery-11@2x.jpg';
import gallery12Thumbnail from './assets/gallery/thumbnails/gallery-12.jpg';
import gallery122xThumbnail from './assets/gallery/thumbnails/gallery-12@2x.jpg';
import gallery13Thumbnail from './assets/gallery/thumbnails/gallery-13.jpg';
import gallery132xThumbnail from './assets/gallery/thumbnails/gallery-13@2x.jpg';
import gallery14Thumbnail from './assets/gallery/thumbnails/gallery-14.jpg';
import gallery142xThumbnail from './assets/gallery/thumbnails/gallery-14@2x.jpg';
import gallery15Thumbnail from './assets/gallery/thumbnails/gallery-15.jpg';
import gallery152xThumbnail from './assets/gallery/thumbnails/gallery-15@2x.jpg';

// import property1 from './assets/similar-properties/property-1.jpg';
// import property12x from './assets/similar-properties/property-1@2x.jpg';
import property2 from './assets/similar-properties/property-2.jpg';
import property22x from './assets/similar-properties/property-2@2x.jpg';
import property3 from './assets/similar-properties/property-3.jpg';
import property32x from './assets/similar-properties/property-3@2x.jpg';
import pet from './assets/similar-properties/pet.jpg';
import tile from './assets/similar-properties/tile.jpg';
import carpet from './assets/similar-properties/carpet.jpg';
import uph from './assets/similar-properties/upholstery.jpg';
import contactImg from './assets/contact.jpg';
import contactImg2x from './assets/contact@2x.jpg';
import {socialLinks} from './socialLinks';
import {sendMail} from './Mailer';

export default {
  title: 'Sparklean',
  navbar: {
    links: [
      {
        to: '',
        'data-to': '',
        label: 'Home',
      },
      {
        to: 'about',
        'data-to': 'about',
        label: 'About',
      },
      {
        to: 'similar-properties',
        'data-to': 'similar-properties',
        label: 'Services',
      },

      {
        to: 'surroundings',
        'data-to': 'surroundings',
        label: 'Our Works',
      },
      // {
      //   to: 'interior',
      //   'data-to': 'interior',
      //   label: 'Price Estimate',
      // },
      {
        to: 'gallery',
        'data-to': 'gallery',
        label: 'Gallery',
      },      {
        to: 'location',
        'data-to': 'location',
        label: 'Location',
      },
      {
        to: 'contact',
        'data-to': 'contact',
        label: 'Contact',
      },
    ],
    actions: [
      {
        to:
          'contact',
        label: 'Get Quote',
      },
    ],
  },
  welcome: {
    background: {
      src: background,
      srcSet: `${background} 1x, ${background2x} 2x`,
      alt: 'Sparklean',
    },
    title: 'SPARKLEAN',
    price: 'Keep it clean and grean',
    features: [
      {
        IconProps: {
          icon: WebGrid,
        },
        title: 'Outdoor',
      },
      {
        IconProps: {
          icon: BedDouble,
        },
        title: 'Indoor',
      },
      {
        IconProps: {
          icon: Bath,
        },
        title: 'Homes',
      },
      {
        IconProps: {
          icon: CarSideview,
        },
        title: 'Corporate',
      },
    ],
    cta: {
      to: 'contact',
      children: 'Contact Us',
    },
  },
  about: {
    title: 'We provide high quality cleaning packages and premium customer services.',
    text:
      'Sparklean has been in the community for years, and in that time we have been fortunate enough to help countless clients with all of their cleaning needs We provide both businesses and homewoners with comprehensive cleaning packages to our clients and are pleased to be an integral part of the local community.',
    gallery: [
      {
        source: aboutLarge1,
        ImgProps: {
          src: about1,
          srcSet: `${about1} 1x, ${about12x} 2x`,
        },
      },
      {
        source: aboutLarge2,
        ImgProps: {
          src: about2,
          srcSet: `${about2} 1x, ${about22x} 2x`,
        },
      },
      {
        source: aboutLarge3,
        ImgProps: {
          src: about3,
          srcSet: `${about3} 1x, ${about32x} 2x`,
        },
      },
    ],
  },
  location: {
    title: textToMultiline`At Sparklean.\n We Employ only the Finest Professionals.`,
    text:
      'In this way, we are able to offer versatile cleaning packages that meet the exact needs of our clients. If you want to work with a team of dedicated professionals that are committed to the cleanliness of your home or business, we are the right choice for you..',
    counters: [
      {
        IconProps: {
          icon: Home,
        },
        title: 'Residential',
        counter: {
          value: 17,
          unit: ' Homes',
        },
      },
      {
        IconProps: {
          icon: Bug,
        },
        title: 'Pet Cleaning',
        counter: {
          value: 20,
          unit: ' Count',
        },
      },
      {
        IconProps: {
          icon: ShoppingBag,
        },
        title: 'Stores',
        counter: {
          value: 12,
          unit: ' Unit',
        },
      },
      {
        IconProps: {
          icon: Bus,
        },
        title: 'Corporate',
        counter: {
          value: 12,
          unit: ' Offices',
        },
      },
      {
        IconProps: {
          icon: Metro,
        },
        title: 'Subway',
        counter: {
          value: 18,
          unit: ' Location',
        },
      },
      {
        IconProps: {
          icon: Beach,
        },
        title: 'Party',
        counter: {
          value: 10,
          unit: ' Unit',
        },
      },
      {
        IconProps: {
          icon: Trees,
        },
        title: 'Any Location',
        counter: {
          value: 8,
          unit: ' Count',
        },
      },
      {
        IconProps: {
          icon: Flag,
        },
        title: 'Golf course',
        counter: {
          value: 3,
          unit: ' Spot',
        },
      },
    ],
    map: {
      cords: {
        lat: 33.827516,
        lng: -84.363709,
      },
    },
  },
  // exterior: {
  //   sections: [
  //     {
  //       ImgProps: {
  //         src: exterior1,
  //         srcSet: `${exterior1} 1x, ${exterior12x} 2x`,
  //       },
  //       title: 'Our Services',
  //       text:
  //         'To provide our customers with all of the cleaning services that they may need,we offer a comprehensive suite of solutions that are designed to cover each area of cleaning. We can help your home or business with our wide-ranging expertise in every area of cleaning..',
  //     },
  //     {
  //       ImgProps: {
  //         src: exterior2,
  //         srcSet: `${exterior2} 1x, ${exterior22x} 2x`,
  //       },
  //       title: 'Carpet :: Residential :: Small Office Cleaning',
  //       text:
  //         'Choose from our wide range of services. We are adding services daily and increasing the number of ways we can be of service to you.',
  //     },
  //   ],
  // },
  interior: {
    sections: [
      {

        title: 'Price Estimation',
        text:
          'Use our quote tool to estimate the cost of cleaning your beautiful home or office.',
      },
      // {
      //   slides: [
      //     {
      //       src: interior1,
      //       srcSet: `${interior1} 1x, ${interior12x} 2x`,
      //     },
      //     {
      //       src: interior1,
      //       srcSet: `${interior1} 1x, ${interior12x} 2x`,
      //     },
      //     {
      //       src: interior1,
      //       srcSet: `${interior1} 1x, ${interior12x} 2x`,
      //     },
      //     {
      //       src: interior1,
      //       srcSet: `${interior1} 1x, ${interior12x} 2x`,
      //     },
      //     {
      //       src: interior1,
      //       srcSet: `${interior1} 1x, ${interior12x} 2x`,
      //     },
      //   ],
      //   title: 'Beautiful bedrooms',
      //   text:
      //     'Vestibulum sed elit id mauris tempus feugiat vel vitae neque. Integer eleifend est orci, quis convallis sem malesuada id. Phasellus fringilla urna vitae lobortis auctor. Vivamus sed nisi non nulla tincidunt ultricies a non ex. Praesent commodo dui enim, at volutpat lectus aliquet ut.',
      // },
    ],
    form: {
      title: 'Type of Service Requested',
      sendButtonText: 'Send',
      validationSchema: Yup.object({
        firstName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
        email: Yup.string().email('Must be an email').required('Required'),
        message: Yup.string().min(20, 'Must be at least 20 characters').required('Required'),
      }),
      // eslint-disable-next-line no-undef
      onSubmit: values => window.alert(`Form sent with values ${JSON.stringify(values)}`),
      fields: [
        {
          name: 'firstName',
          label: 'First name',
          placeholder: 'ie. John Doe',
          initialValue: '',
          prefix: <Icon icon={User} />,
        },
        {
          name: 'phone',
          label: 'Phone',
          placeholder: 'ie. 555-678-123',
          initialValue: '',
          prefix: <Icon icon={Phone} />,
        },
        {
          name: 'email',
          label: 'E-mail',
          placeholder: 'ie. john.doe@email.com',
          type: 'email',
          initialValue: '',
          prefix: <Icon icon={Envelope} />,
        },
        {
          name: 'message',
          label: 'Message',
          placeholder: 'Start typing here...',
          multiline: true,
          initialValue: '',
        },
      ],
    },
  },
  surroundings: {
    sections: [
      {
        ImgProps: {
          src: pet,
          srcSet: `${pet} 1x, ${pet} 2x`,
        },
        title: 'Pet Stain Removal',
        text:
          'Pets take a toll on the cleanliness of your home. We can help clean your home and eliminate pet-related odors while using supplies that are safe for your pets.',
          cta: {
            label: 'Get Service',
            to: 'contact',
            children: 'contact'
          },
      },
      {
        ImgProps: {
          src: uph,
          srcSet: `${uph} 1x, ${uph} 2x`,
        },
        title: 'Upholstery Cleaning',
        text:
          'Dont waste money on a brand new furniture. Sparklean will properly care for your existing one! We provide upholstery cleaning that restores your matteress and helps you live clean.',
        cta: {
          label: 'Get Service',
          to: 'contact',
          children: 'contact'
        },
      },
      {
        ImgProps: {
          src: surrounding3,
          srcSet: `${surrounding3} 1x, ${surrounding32x} 2x`,
        },
        title: 'Residential Cleaning',
        text:
          'Dont settle for low-quality or dusty air. We can purify your air and help you live a more pleasant life and be healthier. Our air dust cleaning helps you breathe easier.',
        cta: {          
          label: 'Get Service',
          to: 'contact',
          children: 'contact'
        },
      },
    ],
  },
  gallery: {
    photos: [
      {
        source: gallery1,
        ImgProps: {
          src: gallery1Thumbnail,
          srcSet: `${gallery1Thumbnail} 1x, ${gallery12xThumbnail} 2x`,
        },
      },
      {
        source: gallery2,
        ImgProps: {
          src: gallery2Thumbnail,
          srcSet: `${gallery2Thumbnail} 1x, ${gallery22xThumbnail} 2x`,
        },
      },
      {
        source: gallery3,
        ImgProps: {
          src: gallery3Thumbnail,
          srcSet: `${gallery3Thumbnail} 1x, ${gallery32xThumbnail} 2x`,
        },
      },
      {
        source: gallery4,
        ImgProps: {
          src: gallery4Thumbnail,
          srcSet: `${gallery4Thumbnail} 1x, ${gallery42xThumbnail} 2x`,
        },
      },
      {
        source: gallery5,
        ImgProps: {
          src: gallery5Thumbnail,
          srcSet: `${gallery5Thumbnail} 1x, ${gallery52xThumbnail} 2x`,
        },
      },
      {
        source: gallery6,
        ImgProps: {
          src: gallery6Thumbnail,
          srcSet: `${gallery6Thumbnail} 1x, ${gallery62xThumbnail} 2x`,
        },
      },
      {
        source: gallery7,
        ImgProps: {
          src: gallery7Thumbnail,
          srcSet: `${gallery7Thumbnail} 1x, ${gallery72xThumbnail} 2x`,
        },
      },
      {
        source: gallery8,
        ImgProps: {
          src: gallery8Thumbnail,
          srcSet: `${gallery8Thumbnail} 1x, ${gallery82xThumbnail} 2x`,
        },
      },
      {
        source: gallery9,
        ImgProps: {
          src: gallery9Thumbnail,
          srcSet: `${gallery9Thumbnail} 1x, ${gallery92xThumbnail} 2x`,
        },
      },
      {
        source: gallery10,
        ImgProps: {
          src: gallery10Thumbnail,
          srcSet: `${gallery10Thumbnail} 1x, ${gallery102xThumbnail} 2x`,
        },
      },
      {
        source: gallery11,
        ImgProps: {
          src: gallery11Thumbnail,
          srcSet: `${gallery11Thumbnail} 1x, ${gallery112xThumbnail} 2x`,
        },
      },
      {
        source: gallery12,
        ImgProps: {
          src: gallery12Thumbnail,
          srcSet: `${gallery12Thumbnail} 1x, ${gallery122xThumbnail} 2x`,
        },
      },
      {
        source: gallery13,
        ImgProps: {
          src: gallery13Thumbnail,
          srcSet: `${gallery13Thumbnail} 1x, ${gallery132xThumbnail} 2x`,
        },
      },
      {
        source: gallery14,
        ImgProps: {
          src: gallery14Thumbnail,
          srcSet: `${gallery14Thumbnail} 1x, ${gallery142xThumbnail} 2x`,
        },
      },
      {
        source: gallery15,
        ImgProps: {
          src: gallery15Thumbnail,
          srcSet: `${gallery15Thumbnail} 1x, ${gallery152xThumbnail} 2x`,
        },
      },
    ],
  },
  contact: {
    title: 'Contact',
    thumbnail: {
      src: contactImg,
      srcSet: `${contactImg} 1x, ${contactImg2x} 2x`,
    },
    details: {
      title: 'Contact Sparklean',
      text:
        'Our dedicated top gents are handy to respond to your enquiries.',
      info: [
        {
          icon: User,
          text: 'Sparklean Admin',
        },
        {
          icon: Building,
          text: 'Cleaning Services',
        },
        {
          icon: MapMarker,
          text: textToMultiline`740 Sidney Marcus Blvd NE.\nAtlanta, GA 30324`,
        },
        {
          icon: Phone,
          text: '+1 470 240 7436',
        },
        {
          icon: Envelope,
          text: 'services@sparkleanmaster.com',
        },
      ],
      socialLinks,
    },
    form: {
      title: 'Send me a message and I will call you back',
      sendButtonText: 'Send',
      validationSchema: Yup.object({
        firstName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
        email: Yup.string().email('Must be an email').required('Required'),
        message: Yup.string().min(20, 'Must be at least 20 characters').required('Required'),
      }),
      // eslint-disable-next-line no-undef
      //onSubmit: values => window.alert(`Form sent with values ${JSON.stringify(values)}`),
      onSubmit: values => sendMail(values, "CONTACT REQUEST"),
      fields: [
        {
          name: 'firstName',
          label: 'First name',
          placeholder: 'ie. John Doe',
          initialValue: '',
          prefix: <Icon icon={User} />,
        },
        {
          name: 'phone',
          label: 'Phone',
          placeholder: 'ie. 555-678-123',
          initialValue: '',
          prefix: <Icon icon={Phone} />,
        },
        {
          name: 'email',
          label: 'E-mail',
          placeholder: 'ie. john.doe@email.com',
          type: 'email',
          initialValue: '',
          prefix: <Icon icon={Envelope} />,
        },
        {
          name: 'message',
          label: 'Message',
          placeholder: 'Start typing here...',
          multiline: true,
          initialValue: '',
        },
      ],
    },
  },
  similarProperties: {
    title: 'Our Services',
    slides: [
      {
        src: interior2,
        srcSet: `${interior2} 1x, ${interior22x} 2x`,
        title:'The Best in town',
        description:'I enjoyed their service, they are the best in town and are absolutely knowledgeable about cleaning, health and hygiene.'
      },
      {
        src: interior2,
        srcSet: `${interior2} 1x, ${interior22x} 2x`,
        title: 'So Professional',
        description:'A friend introduced me to sparklean and I was blown away by their professionalism'
      },
      {
        src: interior2,
        srcSet: `${interior2} 1x, ${interior22x} 2x`,
        title:'Just WOW',
        description: 'I am short of words to describe their services, it is simply awesome and amazing'
      },
      {
        src: interior2,
        srcSet: `${interior2} 1x, ${interior22x} 2x`,
        title:'A friendly company',
        description: 'These guys are so friendly and absolute professionals. They pay attention to every detail.'
      },
      {
        src: interior2,
        srcSet: `${interior2} 1x, ${interior22x} 2x`,
        title: 'I will re-hire them',
        description: 'Once is enough to convince me of their quality, I will hire them over and over again.'
      },
    ],
    properties: [
      {
        ImgProps: {
          src: carpet,
          srcSet: `${carpet} 1x, ${carpet} 2x`,
        },
        LinkProps: {
          as: 'Link',
          to: 'similar-properties',
          children: 'similar-properties'
        },
        title: 'We can help clean your home from the ground up with a comprehensive carpet cleaning package that helps air quality and ensures high-traffic areas are clean',
        price: 'Carpet Cleaning',
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: '3 400 sq. ft.',
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: '5 bedrooms',
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: '3 bathrooms',
          },
          {
            IconProps: {
              icon: CarSideview,
            },
            title: '2 cars garage',
          },
        ],
      },
      {
        ImgProps: {
          src: property2,
          srcSet: `${property2} 1x, ${property22x} 2x`,
        },
        LinkProps: {
          as: 'Link',
          to: 'similar-properties',
          children: 'similar-properties'
        },
        title: 'Dont settle for low-quality or dusty air. We can purify your air and help you live a more pleasant life and be healthier. Our air dust cleaning helps you breathe easier.',
        price: 'Residential Cleaning',
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: '4 200 sq. ft.',
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: '6 bedrooms',
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: '4 bathrooms',
          },
          {
            IconProps: {
              icon: CarSideview,
            },
            title: '2 cars garage',
          },
        ],
      },
      {
        ImgProps: {
          src: property3,
          srcSet: `${property3} 1x, ${property32x} 2x`,
        },
        LinkProps: {
          as: 'Link',
          to: 'similar-properties',
          children: 'similar-properties'
        },
        title: 'If your home has gone through water and fire damage, dont lose hope. We can help restore your home or business from severe water and fire damage.',
        price: 'Small Office Cleaning',
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: '2 800 sq. ft.',
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: '4 bedrooms',
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: '3 bathrooms',
          },
          {
            IconProps: {
              icon: CarSideview,
            },
            title: '2 cars garage',
          },
        ],
      },{
        ImgProps: {
          src: uph,
          srcSet: `${uph} 1x, ${uph} 2x`,
        },
        LinkProps: {
          as: 'Link',
          to: 'similar-properties',
          children: 'similar-properties'
        },
        title: 'Dont waste money on a brand new furniture. Sparklean will properly care for your existing one! We provide upholstery cleaning that restores your matteress and helps you live clean.',
        price: 'Upholstery Cleaning',
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: '2 800 sq. ft.',
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: '4 bedrooms',
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: '3 bathrooms',
          },
          {
            IconProps: {
              icon: CarSideview,
            },
            title: '2 cars garage',
          },
        ],
      },
      {
        ImgProps: {
          src: tile,
          srcSet: `${tile} 1x, ${tile} 2x`,
        },
        LinkProps: {
          as: 'Link',
          to: 'similar-properties',
          children: 'similar-properties'
        },
        title: 'Keep your home looking sparklean (sparkly clean) with our tile and grout cleaning service. Our attention to detail and experience will have your tile looking better than ever.',
        price: 'Tile & Grout Cleaning',
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: '2 800 sq. ft.',
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: '4 bedrooms',
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: '3 bathrooms',
          },
          {
            IconProps: {
              icon: CarSideview,
            },
            title: '2 cars garage',
          },
        ],
      },
      {
        ImgProps: {
          src: pet,
          srcSet: `${pet} 1x, ${pet} 2x`,
        },
        LinkProps: {
          as: 'Link',
          to: 'similar-properties',
          children: 'similar-properties'
        },
        title: 'Pets take a toll on the cleanliness of your home. We can help clean your home and eliminate pet-related odors while using supplies that are safe for your pets.',
        price: 'Pet Stains & Odor',
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: '2 800 sq. ft.',
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: '4 bedrooms',
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: '3 bathrooms',
          },
          {
            IconProps: {
              icon: CarSideview,
            },
            title: '2 cars garage',
          },
        ],
      },
    ],
  },
  footer: {
    title: 'About Sparklean',
    text: textToMultiline`Providing our community with quality and affordable cleaning services that they can depend on. \n\n Call us today to learn more about our services and claim your free quote! `,
    cta: {
      to: 'about',
      children: 'about',
      label: 'Learn more'
    },
   
    features: [
      {
        IconProps: {
          icon: TagAlt,
        },
        title: 'Best price guaranteed',
        text:
          'Proin at convallis felis, sit amet varius velit. Aenean placerat turpis a libero feugiat, at iaculis elit faucibus.',
      },
      {
        IconProps: {
          icon: ThumbsUp,
        },
        title: 'Satisfaction guaranteed',
        text:
          'Proin at convallis felis, sit amet varius velit. Aenean placerat turpis a libero feugiat, at iaculis elit faucibus.',
      },
      {
        IconProps: {
          icon: ShieldCheck,
        },
        title: 'Safety policy',
        text:
          'Proin at convallis felis, sit amet varius velit. Aenean placerat turpis a libero feugiat, at iaculis elit faucibus.',
      },
      {
        IconProps: {
          icon: Award,
        },
        title: 'Professional employees',
        text:
          'Proin at convallis felis, sit amet varius velit. Aenean placerat turpis a libero feugiat, at iaculis elit faucibus.',
      },
    ],
    socialLinks
  },
  copyright: '© 2020 ESSL for Sparklean',
};

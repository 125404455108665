import React from 'react';

export default function Beach(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.9999 36C38.5839 36 33.8859 36.478 29.8459 37.198L26.9979 27.972C28.4139 25.832 30.4759 24.232 32.9439 23.484C35.6553 22.6689 38.5759 22.923 41.1059 24.194C41.7879 24.534 42.6059 24.462 43.2119 23.998C44.2239 23.228 44.0759 22.308 43.7619 21.244C40.8359 11.608 31.2239 5.40397 20.7719 7.47197L20.1479 5.41997C20.0716 5.16862 19.9466 4.93476 19.7799 4.73174C19.6132 4.52872 19.4082 4.36052 19.1765 4.23674C18.9448 4.11296 18.691 4.03602 18.4296 4.01033C18.1682 3.98463 17.9043 4.01067 17.6529 4.08697C17.1453 4.24106 16.7197 4.5905 16.4697 5.0584C16.3459 5.29008 16.269 5.54387 16.2433 5.80529C16.2176 6.0667 16.2436 6.33062 16.3199 6.58197L16.9299 8.59197C6.30391 13.008 2.32591 25.25 5.73791 33.606C5.88009 33.9528 6.11687 34.2527 6.42125 34.4714C6.72563 34.6902 7.08536 34.819 7.45941 34.8432C7.83347 34.8674 8.20679 34.786 8.53681 34.6082C8.86683 34.4305 9.14026 34.1636 9.32591 33.838C10.7186 31.3713 13.0076 29.5356 15.7179 28.712C18.1739 27.966 20.8199 28.16 23.1739 29.152L25.9139 38.024C14.1259 40.87 10.2779 44.818 6.29791 48H45.9999V36H43.9999ZM14.5559 24.882C12.3567 25.5464 10.3404 26.7092 8.66391 28.28C8.09991 20.964 12.6679 13.958 19.9739 11.742C27.3439 9.50397 34.9419 12.868 38.5479 19.208C36.2874 18.8357 33.9716 18.989 31.7799 19.656C28.6726 20.5966 25.9553 22.5221 24.0379 25.142C20.9879 24.0355 17.662 23.9443 14.5559 24.882ZM41.9999 44H20.1719C24.9059 42.162 32.1239 40.252 41.9999 40.022V44Z"
        fill="currentColor"
      />
    </svg>
  );
}
